@charset "UTF-8";
/*
	Stylesheet chybove stranky 404 webu TYMA CZ
*/
/*
    chybová stránka 404
*/
.helper--right img {
  margin: 4.114296em 0 0 0;
}

/***************************************/
/*          odlisne zobrazeni          */
/***************************************/
@media screen and (min-width: 500px) {
  .page404-top {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: -3rem;
  }
  .helper--left {
    -ms-grid-column: 1;
    -ms-grid-row: 1;
  }
  .helper--right {
    -ms-grid-column: 2;
    -ms-grid-row: 1;
  }
}

/*# sourceMappingURL=404.css.map */